<script>
  import { onMount } from "svelte";
  import { fly } from "svelte/transition";
  import { Icon } from "svelte-fontawesome";
  import { faSync } from "@fortawesome/free-solid-svg-icons";

  import {
    collaterals,
    cursList,
    isCollateralLoading,
    isCurListLoading,
    isUserInfoLoading,
    isAdjustLoading,
    isLoanLoading,
  } from "../store";
  import { getCollaterals } from "../services/collateral";
  import { getLoan } from "../services/finance";
  import { getUserData } from "../services/auth";

  import DashboardSpinner from "../components/loading/DashboardSpinner.svelte";
  import OverlaySpinner from "../components/loading/OverlaySpinner.svelte";
  import CollateralItem from "../components/collateral/CollateralItem.svelte";
  import AdjustCollateral from "../components/collateral/AdjustCollateral.svelte";
  import LoanBlock from "../components/collateral/LoanBlock.svelte";
  import Settle from "../components/settle-borrow/Settle.svelte";
  import Borrow from "../components/settle-borrow/Borrow.svelte";

  let collateral_data;

  collaterals.subscribe((v) => {
    collateral_data = v.filter(
      (item) => $cursList[item[0].substr(1, 1) - 1].is_collateral
    );
  });

  onMount(async () => {
    await getUserData();
    await getCollaterals();
    await getLoan();
  });

  const reloadCollateral = async () => {
    await getCollaterals();
  };

  const reloadLoan = async () => {
    await getLoan();
  };
</script>

<AdjustCollateral />

{#if !$isUserInfoLoading}
  <Settle />
  <Borrow />
{/if}

<div in:fly={{ duration: 400, x: -300 }}>
  <div class="collateral">
    <div class="sync-icon" on:click={reloadCollateral}>
      <Icon icon={faSync} />
    </div>
    <p class="fs-5 cl-primary">Collateral</p>

    <div class="collateral-field">
      {#if $isAdjustLoading}
        <OverlaySpinner />
      {/if}
      <div class="collateral-field-size">
        <div class="collateral-header">
          <div class="row mx-0">
            <div class="col-2 text-center cl-primary">Asset</div>
            <div class="col-4 text-center cl-primary">In Collateral</div>
            <div class="col-4 text-center cl-primary">Approx. Value</div>
            <div class="col-2 text-center cl-primary">Action</div>
          </div>
        </div>

        <div class="collateral-list">
          {#if $isCollateralLoading || $isCurListLoading || $isUserInfoLoading}
            <DashboardSpinner />
          {:else}
            {#each collateral_data as collateral}
              <CollateralItem {collateral} />
            {/each}
          {/if}
        </div>
      </div>
    </div>
  </div>

  <div class="loan mt-4 d-flex align-items-center flex-column">
    <div class="sync-icon" on:click={reloadLoan}>
      <Icon icon={faSync} />
    </div>
    <p class="fs-5 cl-primary">Loan Health</p>

    {#if $isCurListLoading || $isLoanLoading || $isUserInfoLoading}
      <DashboardSpinner />
    {:else}
      <div class="mt-1">
        <LoanBlock />
      </div>
    {/if}
  </div>
</div>

<style lang="scss">.collateral,
.loan {
  position: relative;
  background-color: rgba(128, 128, 128, 0.25);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 18px 0 rgba(0, 0, 0, 0.19);
  border-radius: 0.2rem;
  padding: 0.8rem;
}

.sync-icon {
  background-color: #b471ae;
  border-radius: 50%;
  padding: 0.3rem;
  position: absolute;
  right: 0.8rem;
  height: 2.3rem;
  width: 2.3rem;
  color: white;
}

.sync-icon:hover {
  cursor: pointer;
  transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transition: transform 1s ease-in-out;
  -webkit-transition: transform 1s ease-in-out;
}

.collateral-field {
  background-color: white;
  padding: 0 0.5rem 0.5rem 0.5rem;
  border-radius: 0.2rem;
  overflow: auto;
}

.collateral-field-size {
  min-width: 500px;
  max-height: 65vh;
}

.collateral-header {
  position: sticky;
  top: -1px;
  border-bottom: 2px solid #b471ae;
  padding: 1rem 0 0.5rem 0;
  background-color: white;
}

.collateral-list {
  padding: 0.5rem 0;
}</style>
