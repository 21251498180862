<script>
  import { fly } from "svelte/transition";
  import { Tabs, Tab, TabList, TabPanel } from "svelte-tabs";
  import { getNotificationsContext } from "svelte-notifications";

  import { uploadFile } from "../../services/verify";
  import { getUserData } from "../../services/auth";
  import toBase64 from "../../utils/toBase64";
  import { userInfo } from "../../store";

  import ImageUpload from "../file-upload/ImageUpload.svelte";

  const { addNotification } = getNotificationsContext();

  let isProofOfAddr;
  let isIDSelfie;
  let isIDCardFront;
  let isIDCardBack;
  let isIDBook;
  let isProofOfPayment;

  userInfo.subscribe((value) => {
    value.documents.forEach((item) => {
      if (item.type === 2) isProofOfAddr = true;
      else if (item.type === 4) isIDSelfie = true;
      else if (item.type === 5) isIDCardFront = true;
      else if (item.type === 6) isIDCardBack = true;
      else if (item.type === 7) isIDCardBook = true;
      else if (item.type === 50) isProofOfPayment = true;
    });
  });

  const fileUpload = async (file, type) => {
    const base64File = await toBase64(file);

    const newFile = {
      file: base64File,
      file_name: file.name,
      type: type,
    };

    const res = await uploadFile(newFile);

    if (res === 0) {
      await getUserData();

      addNotification({
        text: "Successfully uploaded",
        position: "top-right",
        type: "success",
        removeAfter: 4000,
      });
    }
  };
</script>

<div in:fly={{ duration: 500, x: 300 }} class="d-flex justify-content-center">
  <Tabs>
    <TabList>
      <Tab>ID Card</Tab>
      <Tab>ID Book</Tab>
    </TabList>

    <TabPanel>
      <div class="text-center">
        {#if isProofOfAddr}
          <ImageUpload {fileUpload} title="Proof of address" type={2} />
        {/if}
        {#if isIDSelfie}
          <div class="mt-4">
            <ImageUpload {fileUpload} title="ID selfie" type={4} />
          </div>
        {/if}
        {#if isIDCardFront}
          <div class="mt-4">
            <ImageUpload {fileUpload} title="ID card (front)" type={5} />
          </div>
        {/if}
        {#if isIDCardBack}
          <div class="mt-4">
            <ImageUpload {fileUpload} title="ID card (back)" type={6} />
          </div>
        {/if}
        {#if isProofOfPayment}
          <div class="mt-4">
            <ImageUpload {fileUpload} title="Proof of payment" type={50} />
          </div>
        {/if}
      </div>
    </TabPanel>

    <TabPanel>
      <div class="text-center">
        {#if isProofOfAddr}
          <ImageUpload {fileUpload} title="Proof of address" type={2} />
        {/if}
        {#if isIDSelfie}
          <div class="mt-4">
            <ImageUpload {fileUpload} title="ID selfie" type={4} />
          </div>
        {/if}
        {#if isIDBook}
          <div class="mt-4">
            <ImageUpload {fileUpload} title="ID book" type={7} />
          </div>
        {/if}
        {#if isProofOfPayment}
          <div class="mt-4">
            <ImageUpload {fileUpload} title="Proof of payment" type={50} />
          </div>
        {/if}
      </div>
    </TabPanel>
  </Tabs>
</div>

<style lang="scss">:global(.svelte-tabs .svelte-tabs__tab-list) {
  display: flex;
  min-width: 280px;
}

:global(.svelte-tabs li.svelte-tabs__tab) {
  border: 1px solid #b471ae;
  border-top-left-radius: 1rem;
  border-top-right-radius: 1rem;
  width: 50%;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.25), -4px -4px 10px rgba(100, 100, 100, 0.4), inset 4px 4px 4px rgba(255, 255, 255, 0.2);
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
}

:global(.svelte-tabs li.svelte-tabs__selected) {
  background-color: #b471ae;
  color: white;
}

:global(.svelte-tabs li.svelte-tabs__tab:focus) {
  outline: none;
}</style>
