<script>
  import Modal from "sv-bootstrap-modal";
  import { getNotificationsContext } from "svelte-notifications";
  import { Icon } from "svelte-fontawesome";
  import {
    faCheckToSlot,
    faTimesCircle,
    faInfoCircle,
  } from "@fortawesome/free-solid-svg-icons";

  import {
    settleModal,
    balances,
    collaterals,
    errors,
    cursList,
    userInfo,
  } from "../../store";
  import isEmpty from "../../utils/validation/is-empty";
  import { loanRepay } from "../../services/settle";
  import { getBalances } from "../../services/finance";
  import insertThousandSeparator from "../../utils/thousand_separator";

  const { addNotification } = getNotificationsContext();

  let settleAmount;
  let spotAmount;
  let loanAmount;
  let releaseCollateral = false;
  let disabledRelease = true;
  let text;

  let local_currency = $cursList.find(
    (item) => item.cur_id == $userInfo.local_cur_id
  );

  const onClose = () => {
    settleModal.set(false);
  };

  const onChangeAmount = (e) => {
    errors.set({
      ...$errors,
      settleAmount: "",
    });

    const amount = e.target.value;

    if (amount == loanAmount) {
      disabledRelease = false;
      text = "Your loan will be fully settled";
    } else {
      releaseCollateral = false;
      disabledRelease = true;
      text = `Remaining loan: ZAR ${insertThousandSeparator(
        loanAmount - amount
      )}`;
    }
  };

  const onChecked = () => {
    releaseCollateral = !releaseCollateral;
  };

  const inputMaxAmount = () => {
    errors.set({
      ...$errors,
      settleAmount: "",
    });

    if (spotAmount >= loanAmount) {
      settleAmount = loanAmount.toFixed(local_currency.dec);
      text = "Your loan will be fully settled";
      disabledRelease = false;
    } else {
      settleAmount = spotAmount.toFixed(local_currency.dec);
      text = `Remaining loan: ZAR ${insertThousandSeparator(
        loanAmount - spotAmount
      )}`;
      releaseCollateral = false;
      disabledRelease = true;
    }
  };

  const onSettle = async () => {
    if (settleAmount > spotAmount) {
      errors.set({
        settleAmount: "Over amount of Spot Wallet",
      });
    } else if (settleAmount > loanAmount) {
      errors.set({
        settleAmount: "Over amount of Loan Wallet",
      });
    } else {
      // const zar = $cursList.find((item) => item.cur_id == 1);
      const data = {
        repay_cur_id: 1,
        repay_amount: settleAmount,
        from_cur_id: 1,
        from_wallet_type: 0,
        release_collateral: releaseCollateral,
      };

      const res = await loanRepay(data);

      if (res === 0) {
        settleModal.set(false);

        addNotification({
          text: "Successfully repaid loan",
          position: "top-right",
          type: "success",
          removeAfter: 4000,
        });

        await getBalances();
      } else {
        if (!isEmpty(res.error_text))
          addNotification({
            text: res.error_text,
            position: "top-right",
            type: "danger",
            removeAfter: 4000,
          });
      }
    }
  };

  $: if (!$settleModal) {
    errors.set({});
    settleAmount = "";
    releaseCollateral = false;
    disabledRelease = true;
    text = "";
  }
  $: if (!isEmpty($balances)) {
    const zar = $balances.find((item) => item[0] === "c1");
    let s0;
    let s1;
    let s2 = zar[1].w0.s2;

    if (isEmpty(zar[1].w0.s0)) s0 = 0;
    else s0 = zar[1].w0.s0;

    if (isEmpty(zar[1].w0.s1)) s1 = 0;
    else s1 = zar[1].w0.s1;

    spotAmount = s2 + s1 + s0;

    if (isEmpty(zar[1].w1)) {
      loanAmount = 0;
    } else {
      loanAmount = Math.abs(zar[1].w1.s2);
    }
  }
  $: if (!isEmpty($collaterals)) {
    const zar = $collaterals.find((item) => item[0] === "c1");
    let s0;
    let s1;
    let s2 = zar[1].w0.s2;

    if (isEmpty(zar[1].w0.s0)) s0 = 0;
    else s0 = zar[1].w0.s0;

    if (isEmpty(zar[1].w0.s1)) s1 = 0;
    else s1 = zar[1].w0.s1;

    spotAmount = s2 + s1 + s0;

    if (isEmpty(zar[1].w1)) {
      loanAmount = 0;
    } else {
      loanAmount = Math.abs(zar[1].w1.s2);
    }
  }
</script>

<Modal bind:open={$settleModal} dialogClasses="modal-dialog-centered">
  <div class="modal-header">
    <h5 class="modal-title cl-primary">
      Settle Loan from {local_currency.symbol} Spot Wallet
    </h5>
    <button type="button" class="btn-close" on:click={onClose} />
  </div>

  <div class="modal-body text-start">
    <form on:submit|preventDefault={onSettle} novalidate>
      <div class="row">
        <div class="col-sm-6 my-2">
          <p class="text-center cl-primary">Spot Wallet</p>
          <p class="text-center cl-dark-blue">
            ZAR {insertThousandSeparator(
              spotAmount.toFixed(local_currency.dec)
            )}
          </p>
        </div>
        <div class="col-sm-6 my-2">
          <p class="text-center cl-primary">Existing Loan</p>
          <p class="text-center cl-dark-blue">
            ZAR {insertThousandSeparator(
              loanAmount.toFixed(local_currency.dec)
            )}
          </p>
        </div>
      </div>

      <div class="form-group">
        <label for="settleAmount" class="cl-dark-blue mb-2"
          >Transfer Amount from Spot Wallet:</label
        >
        <div class="input-group">
          <input
            type="number"
            class={`form-control second-input ${
              $errors.settleAmount ? "is-invalid" : null
            }`}
            name="settleAmount"
            id="settleAmount"
            bind:value={settleAmount}
            on:input={onChangeAmount}
            aria-describedby="max-suffix"
          />
          <span
            class="input-group-text"
            id="max-suffix"
            on:click={inputMaxAmount}>Max</span
          >
        </div>
        {#if !isEmpty(text)}
          <div class="my-1 cl-dark-blue d-flex align-items-start">
            <Icon icon={faInfoCircle} class="mt-1" />
            <span class="ms-2">{text}</span>
          </div>
        {/if}
        {#if $errors.settleAmount}
          <div class="text-danger">{$errors.settleAmount}</div>
        {/if}
      </div>

      <div class="form-check mt-2">
        <input
          class="form-check-input"
          type="checkbox"
          checked={releaseCollateral}
          id="release_collateral"
          disabled={disabledRelease}
          on:change={onChecked}
        />
        <label class="form-check-label release-label" for="release_collateral">
          Release spare collateral
        </label>
      </div>

      <div class="d-flex justify-content-center mt-3">
        <!-- <button type="button" class="btn-orange" on:click={onClose}>
          <Icon icon={faTimesCircle} />
          <span class="ms-2">Cancel</span>
        </button> -->
        <button type="submit" class="btn-blue ms-3">
          <Icon icon={faCheckToSlot} />
          <span class="ms-2">Settle</span>
        </button>
      </div>
    </form>
  </div>
</Modal>

<style lang="scss">#max-suffix {
  background-color: #eddeec;
  color: #964f90;
  transition: background-color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out;
}

#max-suffix:hover {
  cursor: pointer;
  background-color: #dcbcd9;
}

.release-label {
  color: #0a5b76;
  cursor: pointer;
}
.release-label:hover {
  color: #b471ae;
}

.form-check-input:checked {
  background-color: #b471ae;
  border-color: #b471ae;
}

.form-check-input:checked,
.form-check-input:focus {
  box-shadow: none !important;
}</style>
