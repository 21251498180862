<script>
  import { fly } from "svelte/transition";
  import { getNotificationsContext } from "svelte-notifications";
  import { Icon } from "svelte-fontawesome";
  import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

  import { userInfo, errors } from "../../store";
  import userInfoValidation from "../../utils/validation/userInfoValidation";
  import { updateUserInfo } from "../../services/auth";
  import isEmpty from "../../utils/validation/is-empty";

  const { addNotification } = getNotificationsContext();

  let userData = {
    email: "",
    firstName: "",
    lastName: "",
    known_as: "",
    id_num: "",
    address: "",
    mobile: "",
  };

  let disabledData = {
    email: false,
    firstName: false,
    lastName: false,
    known_as: false,
    id_num: false,
    address: false,
    mobile: false,
  };

  userInfo.subscribe((value) => {
    if (isEmpty(value.email_unverified)) userData.email = value.email;
    else userData.email = value.email_unverified;

    userData.firstName = value.first_name;
    userData.lastName = value.surname;
    userData.known_as = value.known_as;

    if (value.id_num !== 0) userData.id_num = value.id_num;

    userData.address = value.address;
    userData.mobile = value.mobile;

    disabledData.email =
      value.locks.findIndex((item) => item === "email") >= 0 ? true : false;
    disabledData.firstName =
      value.locks.findIndex((item) => item === "first_name") >= 0
        ? true
        : false;
    disabledData.id_num =
      value.locks.findIndex((item) => item === "id_num") >= 0 ? true : false;
    disabledData.address =
      value.locks.findIndex((item) => item === "address") >= 0 ? true : false;
    disabledData.known_as =
      value.locks.findIndex((item) => item === "known_as") >= 0 ? true : false;
    disabledData.mobile =
      value.locks.findIndex((item) => item === "mobile") >= 0 ? true : false;
    disabledData.lastName =
      value.locks.findIndex((item) => item === "surname") >= 0 ? true : false;
  });

  const onInput = (e) => {
    errors.set({
      ...$errors,
      [e.target.name]: "",
    });
  };

  const saveUserData = async () => {
    const { errorMsgs, isValid } = await userInfoValidation(
      userData,
      disabledData
    );

    if (!isValid) errors.set(errorMsgs);
    else {
      let data = {};

      if (!disabledData.email) data.email = userData.email;
      if (!disabledData.firstName) data.first_name = userData.firstName;
      if (!disabledData.lastName) data.surname = userData.lastName;
      if (!disabledData.known_as) data.known_as = userData.known_as;
      if (!disabledData.id_num) data.id_num = userData.id_num;
      if (!disabledData.address) data.address = userData.address;
      if (!disabledData.mobile) data.mobile = userData.mobile;

      const res = await updateUserInfo(data);

      if (res.result === 0) {
        addNotification({
          text: "Successfully updated",
          position: "top-right",
          type: "success",
          removeAfter: 4000,
        });

        if (!isEmpty(res.message))
          addNotification({
            text: res.message,
            position: "top-center",
            type: "default",
            removeAfter: null,
          });
      } else {
        if (!isEmpty(res.error_text))
          addNotification({
            text: res.error_text,
            position: "top-right",
            type: "danger",
            removeAfter: 4000,
          });
      }
    }
  };
</script>

<div
  class="d-flex justify-content-center align-items-center"
  in:fly={{ duration: 500, x: 300 }}
>
  <div class="account-card text-start">
    <p class="fs-2 text-center cl-primary fw-bold">User Account</p>

    <form on:submit|preventDefault={saveUserData} novalidate>
      <div class="form-group">
        {#if isEmpty($userInfo.email_unverified)}
          <label for="email" class="cl-primary">Email</label>
        {:else}
          <label for="email" class="cl-primary"
            >Verified Email: {$userInfo.email}</label
          >
        {/if}
        <input
          type="email"
          class={`form-control mt-2 second-input ${
            $errors.email ? "is-invalid" : null
          }`}
          name="email"
          id="email"
          disabled={disabledData.email}
          bind:value={userData.email}
          on:input={onInput}
        />
        {#if !isEmpty($userInfo.email_unverified)}
          <div class="d-flex align-items-center cl-dark-blue mt-1">
            <Icon icon={faExclamationTriangle} />
            <span>Waiting for you to verify</span>
          </div>
        {/if}
        {#if $errors.email}
          <div class="text-danger">{$errors.email}</div>
        {/if}
      </div>

      <div class="form-group mt-3">
        <label class="cl-primary" for="firstName">Full Name</label>
        <div class="row">
          <div class="col-sm-6">
            <input
              type="text"
              class={`form-control mt-2 second-input ${
                $errors.firstName ? "is-invalid" : null
              }`}
              name="firstName"
              id="firstName"
              disabled={disabledData.firstName}
              bind:value={userData.firstName}
              on:input={onInput}
            />
            <small class="text-black-50">First Name</small>
            {#if $errors.firstName}
              <div class="text-danger">{$errors.firstName}</div>
            {/if}
          </div>
          <div class="col-sm-6">
            <input
              type="text"
              class={`form-control mt-2 second-input ${
                $errors.lastName ? "is-invalid" : null
              }`}
              name="lastName"
              id="lastName"
              disabled={disabledData.lastName}
              bind:value={userData.lastName}
              on:input={onInput}
            />
            <small class="text-black-50">Last Name</small>
            {#if $errors.lastName}
              <div class="text-danger">{$errors.lastName}</div>
            {/if}
          </div>
        </div>
      </div>

      <div class="form-group">
        <div class="row">
          <div class="col-sm-6 mt-3">
            <label for="known_as" class="cl-primary">Known As</label>
            <input
              type="text"
              class={`form-control mt-2 second-input ${
                $errors.known_as ? "is-invalid" : null
              }`}
              name="known_as"
              id="known_as"
              disabled={disabledData.known_as}
              bind:value={userData.known_as}
              on:input={onInput}
            />
            {#if $errors.known_as}
              <div class="text-danger">{$errors.known_as}</div>
            {/if}
          </div>

          <div class="col-sm-6 mt-3">
            <label for="id_num" class="cl-primary">ID Card Number</label>
            <input
              type="text"
              class={`form-control mt-2 second-input ${
                $errors.id_num ? "is-invalid" : null
              }`}
              name="id_num"
              id="id_num"
              disabled={disabledData.id_num}
              bind:value={userData.id_num}
              on:input={onInput}
            />
            {#if $errors.id_num}
              <div class="text-danger">{$errors.id_num}</div>
            {/if}
          </div>
        </div>
      </div>

      <div class="form-group mt-3">
        <label for="address" class="cl-primary">Physical Address</label>
        <textarea
          type="text"
          class={`form-control mt-2 second-input ${
            $errors.address ? "is-invalid" : null
          }`}
          name="address"
          id="address"
          disabled={disabledData.address}
          rows={3}
          bind:value={userData.address}
          on:input={onInput}
        />
        {#if $errors.address}
          <div class="text-danger">{$errors.address}</div>
        {/if}
      </div>

      <div class="form-group mt-3">
        <label class="cl-primary" for="mobile">Phone Number</label>
        <input
          type="text"
          class={`form-control mt-2 second-input ${
            $errors.mobile ? "is-invalid" : null
          }`}
          name="mobile"
          id="mobile"
          disabled={disabledData.mobile}
          bind:value={userData.mobile}
          on:input={onInput}
        />
        {#if $errors.mobile}
          <div class="text-danger">{$errors.mobile}</div>
        {/if}
      </div>

      <button type="submit" class="btn-main mt-3 full-width">Update</button>
    </form>
  </div>
</div>

<style lang="scss">.account-card {
  position: relative;
  background-color: rgba(128, 128, 128, 0.25);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 18px 0 rgba(0, 0, 0, 0.19);
  border-radius: 0.3rem;
  padding: 0.8rem;
  width: fit-content;
}</style>
