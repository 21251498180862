<script>
  import { Icon } from "svelte-fontawesome";
  import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

  export let disabledNext;
  export let nextPage;

  const setNextPage = () => {
    if (!disabledNext) nextPage();
  };
</script>

<div
  class={`${disabledNext ? "disable-btn" : "next-btn"}`}
  on:click={setNextPage}
>
  <Icon icon={faChevronRight} />
</div>

<style lang="scss">.disable-btn {
  margin-top: 2px;
  color: #adadad;
  padding: 0.5rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
}

.next-btn {
  margin-top: 2px;
  color: #0a5b76;
  padding: 0.5rem 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
}

.next-btn:hover {
  cursor: pointer;
  color: #b471ae;
  background-color: rgba(180, 113, 174, 0.25);
}</style>
