<script>
  import { onMount } from "svelte";
  import { fly } from "svelte/transition";
  import { Icon } from "svelte-fontawesome";
  import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
  import Select from "svelte-select";
  import { navigate } from "svelte-navigator";
  import { getNotificationsContext } from "svelte-notifications";

  import {
    cursList,
    curTypes,
    asset_items,
    account_items,
    fiat_items,
    asset_amounts,
    cryptoWhitelist,
    errors,
    isBalanceLoading,
    isCurListLoading,
    isCurTypeLoading,
    isFiatWhitelistLoading,
    isCryptoWhitelistLoading,
    isBankListLoading,
    initializeData,
    createAccount,
  } from "../store";
  import { getBalances } from "../services/finance";
  import { getBankList } from "../services/create-account";
  import {
    getCryptoWhitelist,
    getFiatWhitelist,
    withdrawFiat,
    withdrawCrypto,
  } from "../services/withdraw";
  import withdrawValidation from "../utils/validation/withdrawValidation";
  import isEmpty from "../utils/validation/is-empty";
  import cutDecimal from "../utils/cut_decimal";
  import insertThousandSeparator from "../utils/thousand_separator";

  import DashboardSpinner from "../components/loading/DashboardSpinner.svelte";

  const { addNotification } = getNotificationsContext();

  onMount(async () => {
    await getBalances();
    await getBankList();
    await getFiatWhitelist();
    await getCryptoWhitelist();
  });

  let assetItems = [];
  let accountItems = [];
  let disabledAccount = true;

  let asset;
  let account;
  let amount;
  let balance = 0;
  let fee = 0;
  let accountTitle = "Bank account:";
  let isFiat = true;
  let selectedCur;

  let curListLoading;
  let curTypeLoading;
  let balancesLoading;
  let fiatWhitelistLoading;
  let cryptoWhitelistLoading;
  let bankListLoading;

  isCurListLoading.subscribe((v) => {
    curListLoading = v;
  });
  isCurTypeLoading.subscribe((v) => {
    curTypeLoading = v;
  });
  isBalanceLoading.subscribe((v) => {
    balancesLoading = v;
  });
  isFiatWhitelistLoading.subscribe((v) => {
    fiatWhitelistLoading = v;
  });
  isCryptoWhitelistLoading.subscribe((v) => {
    cryptoWhitelistLoading = v;
  });
  isBankListLoading.subscribe((v) => {
    bankListLoading = v;
  });
  asset_items.subscribe((v) => {
    assetItems = v;
  });
  account_items.subscribe((v) => {
    accountItems = v;
  });

  $: {
    if (
      !curListLoading &&
      !curTypeLoading &&
      !balancesLoading &&
      !fiatWhitelistLoading &&
      !cryptoWhitelistLoading &&
      !bankListLoading
    ) {
      initializeData();
    }
  }

  const handleSelectAsset = (e) => {
    asset = e.detail;
    account = undefined;

    errors.set({
      ...$errors,
      asset: "",
    });

    let currentAsset = $asset_amounts.find(
      (item) => item.cur_id == asset.value
    );

    balance = currentAsset.amount;
    fee = currentAsset.fee;

    selectedCur = $cursList.find((item) => item.cur_id == e.detail.value);
    let cur_type = $curTypes.find(
      (item) => item.cur_type_id == selectedCur.cur_type
    );

    if (cur_type.name === "fiat") {
      accountTitle = "Bank account:";
      isFiat = true;
      account_items.set($fiat_items);
    } else {
      accountTitle = "Crypto address:";
      isFiat = false;

      let accounts = $cryptoWhitelist.filter(
        (item) => item.cur_id == e.detail.value
      );
      let items = [];

      accounts.forEach((item) => {
        if (item.state === "confirmed")
          items.push({
            value: item.id,
            label: item.name,
          });
      });
      account_items.set(items);
    }

    disabledAccount = false;
  };

  const handleClearAsset = () => {
    disabledAccount = true;
    asset = undefined;
    account = undefined;
    balance = 0;
    fee = 0;
  };

  const handleSelectAccount = (e) => {
    account = e.detail;

    errors.set({
      ...$errors,
      account: "",
    });
  };

  const handleClearAccount = () => {
    account = undefined;
  };

  const onChangeAmount = () => {
    errors.set({
      ...$errors,
      amount: "",
    });
  };

  const inputMaxAmount = () => {
    if (isEmpty(asset)) {
      errors.set({
        ...$errors,
        asset: "require",
      });
    } else if (balance - fee <= 0) {
      errors.set({
        ...$errors,
        amount: "Invalid balance",
      });
    } else {
      let currentAsset = $asset_amounts.find(
        (item) => item.cur_id == asset.value
      );

      amount = cutDecimal(
        currentAsset.amount - currentAsset.fee,
        currentAsset.dec_withdraw
      );

      errors.set({
        ...$errors,
        amount: "",
      });
    }
  };

  const navigateBankList = () => {
    createAccount.set("Bank");
    navigate("create-account#bank");
  };

  const navigateCryptoList = () => {
    createAccount.set("Crypto");
    navigate("create-account#crypto");
  };

  const onSubmitWithdraw = async () => {
    const { errorMsgs, isValid } = withdrawValidation({
      asset: asset,
      account: account,
      amount: parseFloat(amount),
    });

    if (!isValid) errors.set(errorMsgs);
    else {
      let currency = $cursList.find((item) => item.cur_id == asset.value);
      let cur_type = $curTypes.find(
        (item) => item.cur_type_id == currency.cur_type
      );

      if (cur_type.name === "fiat") {
        const data = {
          bank_acc_id: account.value,
          amount: amount,
        };

        const res = await withdrawFiat(data);

        if (res === 0) {
          asset = undefined;
          account = undefined;
          amount = "";
          balance = 0;
          fee = 0;

          addNotification({
            text: "Successfully withdrawn",
            position: "top-right",
            type: "success",
            removeAfter: 4000,
          });
        } else {
          if (!isEmpty(res.error_text))
            addNotification({
              text: res.error_text,
              position: "top-right",
              type: "danger",
              removeAfter: 4000,
            });
        }
      } else {
        const data = {
          send_addr_id: account.value,
          amount: amount,
        };

        const res = await withdrawCrypto(data);

        if (res === 0) {
          addNotification({
            text: "Successfully withdrawn",
            position: "top-right",
            type: "success",
            removeAfter: 4000,
          });
        } else {
          if (!isEmpty(res.error_text))
            addNotification({
              text: res.error_text,
              position: "top-right",
              type: "danger",
              removeAfter: 4000,
            });
        }
      }
    }
  };
</script>

<div class="d-flex justify-content-center" in:fly={{ duration: 400, x: 300 }}>
  <div class="withdraw text-start">
    <p class="fs-5 cl-primary text-center">Withdraw</p>

    {#if !curListLoading && !curTypeLoading && !balancesLoading && !fiatWhitelistLoading && !cryptoWhitelistLoading && !bankListLoading}
      <form
        on:submit|preventDefault={onSubmitWithdraw}
        in:fly={{ duration: 400, y: 400 }}
        novalidate
      >
        <div class="form-group">
          <label for="asset" class="cl-dark-blue mb-1">Asset:</label>
          <Select
            items={assetItems}
            value={asset}
            id="asset"
            containerClasses="withdraw-select"
            on:select={handleSelectAsset}
            on:clear={handleClearAsset}
          />
          {#if $errors.asset}
            <div class="text-danger">{$errors.asset}</div>
          {/if}
        </div>

        <div class="form-group mt-3">
          <label for="account" class="cl-dark-blue mb-1">{accountTitle}</label>
          <Select
            items={accountItems}
            value={account}
            id="account"
            containerClasses="withdraw-select"
            isDisabled={disabledAccount}
            on:select={handleSelectAccount}
            on:clear={handleClearAccount}
          />
          {#if $errors.account}
            <div class="text-danger">{$errors.account}</div>
          {/if}
          <div class="text-end mt-1">
            {#if isFiat}
              <span
                class="cl-dark-blue create-account mb-0 mt-1"
                on:click={navigateBankList}
              >
                Manage Bank Accounts
              </span>
            {:else}
              <span
                class="cl-dark-blue create-account mb-0 mt-1"
                on:click={navigateCryptoList}
              >
                Manage Crypto Addresses
              </span>
            {/if}
          </div>
        </div>

        <div class="form-group mt-2">
          <label for="amount" class="cl-dark-blue mb-2">Amount:</label>
          <div class="input-group">
            <input
              type="number"
              class="form-control second-input"
              name="amount"
              id="amount"
              bind:value={amount}
              on:input={onChangeAmount}
              aria-describedby="max-suffix"
            />
            <span
              class="input-group-text"
              id="max-suffix"
              on:click={inputMaxAmount}>Max</span
            >
          </div>
          {#if $errors.amount}
            <div class="text-danger">{$errors.amount}</div>
          {/if}
        </div>

        <p class="my-3 cl-dark-blue">
          Balance: <span class="fs-6"
            >{isEmpty(selectedCur)
              ? insertThousandSeparator(balance)
              : insertThousandSeparator(balance.toFixed(selectedCur.dec))}</span
          >
        </p>
        <p class="cl-dark-blue">Fee: <span class="fs-6">{fee}</span></p>

        <div class="d-flex justify-content-center">
          <button type="submit" class="btn-main me-3">
            <Icon icon={faMinusCircle} />
            <span class="ms-2">Withdraw</span>
          </button>
        </div>
      </form>
    {:else}
      <DashboardSpinner />
    {/if}
  </div>
</div>

<style lang="scss">.withdraw {
  position: relative;
  background-color: rgba(128, 128, 128, 0.25);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 18px 0 rgba(0, 0, 0, 0.19);
  border-radius: 0.2rem;
  padding: 0.8rem;
  width: fit-content;
  min-width: 250px;
}

#max-suffix {
  background-color: #eddeec;
  color: #964f90;
  transition: background-color 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s ease-in-out;
}

#max-suffix:hover {
  cursor: pointer;
  background-color: #dcbcd9;
}

.create-account:hover {
  text-decoration: underline;
  cursor: pointer;
  color: #b471ae;
}</style>
