<script>
  import { fly } from "svelte/transition";

  import { cursList, transType } from "../../store";
  import insertThousandSeparator from "../../utils/thousand_separator";

  export let transaction;

  let dr_cur = $cursList.find((item) => item.cur_id == transaction.dr_cur_id);
  let cr_cur = $cursList.find((item) => item.cur_id == transaction.cr_cur_id);
  let fee_cur = $cursList.find((item) => item.cur_id == transaction.fee_cur_id);
  let show_dr =
    transaction.type === 1 ||
    transaction.type === 4 ||
    transaction.type === 5 ||
    transaction.type === 6 ||
    transaction.type === 8 ||
    transaction.type === 10 ||
    transaction.type === 11 ||
    transaction.type === 12 ||
    transaction.type === 13;
  let show_cr =
    transaction.type === 2 ||
    transaction.type === 3 ||
    (transaction.type === 5 &&
      transaction.dr_cur_id !== transaction.cr_cur_id) ||
    transaction.type === 7 ||
    (transaction.type === 8 &&
      transaction.dr_cur_id !== transaction.cr_cur_id) ||
    (transaction.type === 10 &&
      transaction.dr_cur_id !== transaction.cr_cur_id) ||
    (transaction.type === 11 &&
      transaction.dr_cur_id !== transaction.cr_cur_id) ||
    (transaction.type === 12 &&
      transaction.dr_cur_id !== transaction.cr_cur_id) ||
    (transaction.type === 13 &&
      transaction.dr_cur_id !== transaction.cr_cur_id);
</script>

<div
  class="row mx-0 py-1 my-1 transaction-item"
  in:fly={{ duration: 500, y: 500 }}
>
  <div class="col-2 d-flex flex-wrap justify-content-center align-items-center">
    <div>
      <span>{new Date(transaction.time).toLocaleDateString()}</span>
      <span>{new Date(transaction.time).toLocaleTimeString("it-IT")}</span>
    </div>
  </div>
  <div class="col-2 d-flex justify-content-center align-items-center">
    {$transType[parseInt(transaction.type - 1)]}
  </div>
  <div
    class="col-2 d-flex flex-column justify-content-center align-items-center"
  >
    {#if show_dr}
      <div class="d-flex justify-content-center align-items-center">
        <img
          src={`../../vendor/image/${dr_cur.icon_image}`}
          alt="asset"
          class="asset-icon me-2"
        />
        <span>{dr_cur.symbol}</span>
      </div>
    {/if}
    {#if show_cr}
      <div class="d-flex justify-content-center align-items-center">
        <img
          src={`../../vendor/image/${cr_cur.icon_image}`}
          alt="asset"
          class="asset-icon me-2"
        />
        <span>{cr_cur.symbol}</span>
      </div>
    {/if}
  </div>
  <div
    class="col-3 d-flex flex-column justify-content-center align-items-center"
  >
    {#if show_dr}
      <span
        >{insertThousandSeparator(
          transaction.dr_amount.toFixed(dr_cur.dec)
        )}</span
      >
    {/if}
    {#if show_cr}
      <span
        >{insertThousandSeparator(
          transaction.cr_amount.toFixed(cr_cur.dec)
        )}</span
      >
    {/if}
  </div>
  <div class="col-3 d-flex justify-content-center align-items-center">
    {#if transaction.fee_amount !== 0}
      <!-- content here -->
      <span class="me-2">{fee_cur.symbol}</span>
      <span
        >{insertThousandSeparator(
          Math.abs(transaction.fee_amount).toFixed(fee_cur.dec)
        )}</span
      >
    {:else}
      -
    {/if}
  </div>
</div>

<style lang="scss">.asset-icon {
  width: 1.8rem;
}

.transaction-item {
  border-radius: 0.2rem;
}

.transaction-item:hover {
  background-color: rgba(180, 113, 174, 0.25);
}</style>
