<script>
  import { fly, fade } from "svelte/transition";
  import Popover from "svelte-easy-popover";
  import { Icon } from "svelte-fontawesome";
  import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";

  import { cursList } from "../../store";
  import isEmpty from "../../utils/validation/is-empty";
  import insertThousandSeparator from "../../utils/thousand_separator";

  export let asset;

  let referenceInfo;
  let currency = $cursList.find((item) => item.cur_id == asset[0].substr(1, 1));

  let spotS2 = asset[1].w0.s2;
  let spotS1;
  let spotS0;

  let showInfoIcon = false;

  if (!isEmpty(asset[1].w0.s1)) spotS1 = asset[1].w0.s1;
  if (!isEmpty(asset[1].w0.s0)) spotS1 = asset[1].w0.s0;

  if (!isEmpty(spotS0) || !isEmpty(spotS1)) showInfoIcon = true;
</script>

<div class="row mx-0 py-1 my-1 wallet-item" in:fly={{ duration: 500, y: 500 }}>
  <div
    class="col-2 text-center d-flex justify-content-center align-items-center"
  >
    <img
      src={`../../vendor/image/${currency.icon_image}`}
      alt="asset"
      class="asset-icon me-2"
    />
    <span>{currency.symbol}</span>
  </div>
  <div class="col-4 text-center spot-wallet">
    {asset[1].w0
      ? spotS2 !== 0
        ? insertThousandSeparator(spotS2.toFixed(currency.dec))
        : "-"
      : "-"}
    {#if showInfoIcon}
      <div class="d-flex info-icon me-2" bind:this={referenceInfo}>
        <Icon icon={faInfoCircle} />
      </div>
    {/if}
  </div>
  <div class="col-3 text-center">
    {asset[1].w1
      ? asset[1].w1.s2 !== 0
        ? insertThousandSeparator(asset[1].w1.s2.toFixed(currency.dec))
        : "-"
      : "-"}
  </div>
  <div class="col-3 text-center">
    {asset[1].w2
      ? asset[1].w2.s2 !== 0
        ? insertThousandSeparator(asset[1].w2.s2.toFixed(currency.dec))
        : "-"
      : "-"}
  </div>
</div>

<Popover
  triggerEvents={["focus", "hover"]}
  referenceElement={referenceInfo}
  placement="left"
  spaceAway={7}
>
  <div class="info-text" transition:fade={{ duration: 250 }}>
    {#if !isEmpty(spotS0)}
      <p class="my-1">Requested: {spotS0}</p>
    {/if}
    {#if !isEmpty(spotS1)}
      <p class="my-1">Processing: {spotS1}</p>
    {/if}
  </div>
</Popover>

<style lang="scss">.asset-icon {
  width: 1.8rem;
}

.wallet-item {
  border-radius: 0.2rem;
}

.wallet-item:hover {
  background-color: rgba(180, 113, 174, 0.25);
}

.info-icon {
  position: absolute;
  top: 4px;
  right: 0;
  font-size: 1.25rem;
  transition: color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out;
}

.info-icon:hover {
  cursor: pointer;
  color: #13b5ec;
}

.info-text {
  background-color: #13b5ec;
  color: white;
  padding: 0.25rem;
  border-radius: 0.2rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 18px 0 rgba(0, 0, 0, 0.19);
}

.spot-wallet {
  position: relative;
}</style>
