<script>
  import { onMount } from "svelte";
  import { navigate } from "svelte-navigator";

  import { profilePage } from "../store";
  import { getUserData } from "../services/auth";
  import SideMenu from "../components/profile/SideMenu.svelte";
  import Account from "../components/profile/Account.svelte";
  import ChangePassword from "../components/profile/ChangePassword.svelte";
  import Setting from "../components/profile/Setting.svelte";
  import Security from "../components/profile/Security.svelte";
  import Verify from "../components/profile/Verify.svelte";

  onMount(async () => {
    await getUserData();

    if (window.location.hash === "#account") profilePage.set("Account");
    else if (window.location.hash === "#password") profilePage.set("Password");
    else if (window.location.hash === "#verify") profilePage.set("Verify");
    else if (window.location.hash === "#setting") profilePage.set("Setting");
    else if (window.location.hash === "#security") profilePage.set("Security");
    else {
      window.location.hash = "#account";
      profilePage.set("Account");
    }
  });
</script>

<SideMenu />

<div class="left-padding">
  {#if $profilePage === "Account"}
    <Account />
  {/if}
  {#if $profilePage === "Password"}
    <ChangePassword />
  {/if}
  {#if $profilePage === "Verify"}
    <Verify />
  {/if}
  {#if $profilePage === "Setting"}
    <Setting />
  {/if}
  {#if $profilePage === "Security"}
    <Security />
  {/if}
</div>

<style lang="scss">.left-padding {
  padding-left: 2rem;
}</style>
