<script>
  import { onMount } from "svelte";
  import { fly } from "svelte/transition";
  import { getNotificationsContext } from "svelte-notifications";
  import Switch from "svelte-switch";

  import { setting, getUserData } from "../../services/auth";
  import { userInfo } from "../../store";

  const { addNotification } = getNotificationsContext();

  let settings;

  userInfo.subscribe((v) => {
    settings = v.settings;
  });

  onMount(async () => {
    await getUserData();
  });

  const changeSwitch = async (e) => {
    const data = {
      [e.detail.id]: e.detail.checked,
    };

    const res = await setting(data);

    if (res !== 0) {
      if (!isEmpty(res.error_text))
        addNotification({
          text: res.error_text,
          position: "top-right",
          type: "danger",
          removeAfter: 4000,
        });
    }
  };
</script>

<p class="fs-2 fw-bold text-center cl-primary">Settings</p>

<div in:fly={{ duration: 500, x: 300 }} class="d-flex justify-content-center">
  <div class="setting-card">
    <p class="fs-5 cl-primary">General</p>

    <div class="d-flex justify-content-start align-items-center">
      <Switch
        on:change={changeSwitch}
        checked={settings.auto_settle_from_spot_before_sell}
        onColor="#b471ae"
        activeBoxShadow="0 0 2px 3px #b471ae"
        height={25}
        width={50}
        id="auto_settle_from_spot_before_sell"
      />
      <span class="ms-2 cl-dark-blue"
        >First settle from spot ZAR before liquididating any crypto</span
      >
    </div>
  </div>
</div>

<div
  in:fly={{ duration: 500, x: 300 }}
  class="d-flex justify-content-center mt-4"
>
  <div class="setting-card">
    <p class="fs-5 cl-primary">App / Browser Notifications</p>

    <div class="d-flex justify-content-start align-items-center">
      <Switch
        on:change={changeSwitch}
        checked={settings.app_notify_ltv_warn}
        onColor="#b471ae"
        activeBoxShadow="0 0 2px 3px #b471ae"
        height={25}
        width={50}
        id="app_notify_ltv_warn"
      />
      <span class="ms-2 cl-dark-blue">LTV warnings</span>
    </div>

    <div class="d-flex justify-content-start align-items-center mt-4">
      <Switch
        on:change={changeSwitch}
        checked={settings.app_notify_sell}
        onColor="#b471ae"
        activeBoxShadow="0 0 2px 3px #b471ae"
        height={25}
        width={50}
        id="app_notify_sell"
      />
      <span class="ms-2 cl-dark-blue">LTV liquidations</span>
    </div>
  </div>
</div>

<div
  in:fly={{ duration: 500, x: 300 }}
  class="d-flex justify-content-center mt-4"
>
  <div class="setting-card">
    <p class="fs-5 cl-primary">Email Notifications</p>

    <div class="d-flex justify-content-start align-items-center">
      <Switch
        on:change={changeSwitch}
        checked={settings.email_ltv_warn}
        onColor="#b471ae"
        activeBoxShadow="0 0 2px 3px #b471ae"
        height={25}
        width={50}
        id="email_ltv_warn"
      />
      <span class="ms-2 cl-dark-blue">LTV warnings</span>
    </div>

    <div class="d-flex justify-content-start align-items-center mt-4">
      <Switch
        on:change={changeSwitch}
        checked={settings.email_sell}
        onColor="#b471ae"
        activeBoxShadow="0 0 2px 3px #b471ae"
        height={25}
        width={50}
        id="email_sell"
      />
      <span class="ms-2 cl-dark-blue">LTV liquidations</span>
    </div>

    <div class="d-flex justify-content-start align-items-center mt-4">
      <Switch
        on:change={changeSwitch}
        checked={settings.email_weekly_summary}
        onColor="#b471ae"
        activeBoxShadow="0 0 2px 3px #b471ae"
        height={25}
        width={50}
        id="email_weekly_summary"
      />
      <span class="ms-2 cl-dark-blue">Weekly transaction summary</span>
    </div>
  </div>
</div>

<style lang="scss">.setting-card {
  position: relative;
  background-color: rgba(128, 128, 128, 0.25);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 18px 0 rgba(0, 0, 0, 0.19);
  border-radius: 0.3rem;
  padding: 0.8rem;
  width: 100%;
  max-width: 500px;
}</style>
