<script>
  import { onMount } from "svelte";
  import { fly } from "svelte/transition";
  import { navigate } from "svelte-navigator";

  import { createAccount } from "../../services/auth";
  import { errors, signupSuccessModal } from "../../store";
  import signupValidation from "../../utils/validation/signupValidation";

  import SignUpSuccessModal from "../../components/auth/SignUpSuccessModal.svelte";

  let userData = {
    email: "",
    password: "",
    password2: "",
  };

  let errMessages;

  errors.subscribe((value) => {
    errMessages = value;
  });

  onMount(() => {
    if (localStorage.auth_token) {
      localStorage.removeItem("auth_token");
    }

    if (localStorage.token) {
      navigate("/", { replace: true });
    }
  });

  const navigateSingin = () => {
    errors.set({});
    navigate("/signin", { replace: true });
  };

  const onInput = (e) => {
    errors.set({
      ...$errors,
      [e.target.name]: "",
    });
  };

  const signup = async () => {
    const { errorMsgs, isValid } = await signupValidation(userData);

    if (!isValid) errors.set(errorMsgs);
    else {
      const res = await createAccount(userData);

      if (res === 0) signupSuccessModal.set(true);
    }
  };
</script>

<SignUpSuccessModal />
<div
  class="d-flex justify-content-center align-items-center"
  in:fly={{ duration: 500, x: 300 }}
>
  <div class="fit-content-width text-start">
    <p class="fs-2 cl-primary fw-bold">Create Your Coin Stash Account</p>

    <form on:submit|preventDefault={signup} novalidate>
      <div class="form-group">
        <label for="email" class="cl-primary">Email</label>
        <input
          type="email"
          class={`form-control mt-2 primary-input ${
            errMessages.email ? "is-invalid" : null
          }`}
          name="email"
          id="email"
          bind:value={userData.email}
          on:input={onInput}
        />
        {#if errMessages.email}
          <div class="text-danger">{errMessages.email}</div>
        {/if}
      </div>

      <div class="form-group mt-3">
        <label for="password" class="cl-primary">Password</label>
        <input
          type="password"
          class={`form-control mt-2 primary-input ${
            errMessages.password ? "is-invalid" : null
          }`}
          name="password"
          id="password"
          bind:value={userData.password}
          on:input={onInput}
        />
        {#if errMessages.password}
          <div class="text-danger">{errMessages.password}</div>
        {/if}
      </div>

      <div class="form-group mt-3">
        <label for="password2" class="cl-primary">Confirm Password</label>
        <input
          type="password"
          class={`form-control mt-2 primary-input ${
            errMessages.password2 ? "is-invalid" : null
          }`}
          name="password2"
          id="password2"
          bind:value={userData.password2}
          on:input={onInput}
        />
        {#if errMessages.password2}
          <div class="text-danger">{errMessages.password2}</div>
        {/if}
      </div>

      <button type="submit" class="btn-main mt-4 full-width"
        >Create Account</button
      >
    </form>
    <hr class="mt-4" />
    <div>
      <span>Already have an account?</span>
      <span class="cl-primary auth-link" on:click={navigateSingin}>
        Sign In
      </span>
    </div>
  </div>
</div>

<style lang="scss">.auth-link {
  text-decoration: none !important;
  color: #964f90 !important;
}

.auth-link:hover {
  color: #964f90 !important;
  text-decoration: underline !important;
  cursor: pointer;
}</style>
