<script>
  import { fly } from "svelte/transition";
  import { navigate } from "svelte-navigator";
  import { Icon } from "svelte-fontawesome";
  import {
    faExclamationTriangle,
    faArrowCircleRight,
  } from "@fortawesome/free-solid-svg-icons";
  import { getNotificationsContext } from "svelte-notifications";

  import { deleteAccountWarning } from "../../services/auth";
  import isEmpty from "../../utils/validation/is-empty";

  const { addNotification } = getNotificationsContext();

  export let warning;

  const gotoLink = () => {
    navigate(`/${warning.link}`, { replace: true });
  };

  const onClose = async () => {
    const res = await deleteAccountWarning(warning.type);

    if (res !== 0) {
      if (!isEmpty(res.error_text))
        addNotification({
          text: res.error_text,
          position: "top-right",
          type: "danger",
          removeAfter: 4000,
        });
    }
  };
</script>

<div class="warning-card mb-4" transition:fly={{ duration: 400, x: 300 }}>
  <div class="d-flex justify-content-between align-items-center">
    <div class="d-flex align-items-center">
      <Icon icon={faExclamationTriangle} class="fs-5 text-danger" />
      <span class="fs-5 text-danger ms-2">{warning.title}</span>
    </div>

    {#if warning.can_close}
      <button type="button" class="btn-close cl-dark-blue" on:click={onClose} />
    {/if}
  </div>

  <p class="cl-dark-blue text-start mt-3 mb-0 me-2">
    {warning.html}
    {#if warning.link !== false}
      <span class="goto-link" on:click={gotoLink}>
        <Icon icon={faArrowCircleRight} />
      </span>
    {/if}
  </p>
</div>

<style lang="scss">.warning-card {
  position: relative;
  background-color: rgba(128, 128, 128, 0.25);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 18px 0 rgba(0, 0, 0, 0.19);
  border-radius: 0.2rem;
  padding: 0.8rem;
}

.goto-link {
  color: #0a5b76;
  cursor: pointer;
  transition: color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out;
}
.goto-link:hover {
  color: #b471ae;
}</style>
