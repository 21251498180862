<script>
  import { faLandmark, faCoins } from "@fortawesome/free-solid-svg-icons";
  import { navigate } from "svelte-navigator";
  import { fly } from "svelte/transition";

  import SideMenuBarItem from "./SideMenuBarItem.svelte";
  import { createAccount } from "../../store";

  const switchPages = (title) => {
    createAccount.set(title);
    navigate(`#${title.toLowerCase()}`);
  };
</script>

<div class="sidebar" transition:fly={{ duration: 500, x: -300 }}>
  <SideMenuBarItem title="Bank" icon={faLandmark} {switchPages} />
  <SideMenuBarItem title="Crypto" icon={faCoins} {switchPages} />
</div>

<style lang="scss">.sidebar {
  position: fixed;
  left: 0;
  z-index: 999;
}

:global(a) {
  color: inherit !important;
  text-decoration: none !important;
}</style>
