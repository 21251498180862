<script>
  import { onMount } from "svelte";
  import { navigate } from "svelte-navigator";

  import {
    createAccount,
    isCurListLoading,
    isCurTypeLoading,
    filterCurrency,
    isBankListLoading,
  } from "../store";
  import {
    getBankAccounts,
    getCryptoAccounts,
    getBankList,
  } from "../services/create-account";

  import SideMenuBar from "../components/create-account/SideMenuBar.svelte";
  import BankAccount from "../components/create-account/BankAccount.svelte";
  import CryptoAccount from "../components/create-account/CryptoAccount.svelte";

  let curListLoading;
  let curTypeLoading;
  let bankListLoading;

  isCurListLoading.subscribe((v) => {
    curListLoading = v;
  });
  isCurTypeLoading.subscribe((v) => {
    curTypeLoading = v;
  });
  isBankListLoading.subscribe((v) => {
    bankListLoading = v;
  });

  onMount(async () => {
    await getBankList();
    await getBankAccounts();
    await getCryptoAccounts();

    if (window.location.hash === "#bank") createAccount.set("Bank");
    else if (window.location.hash === "#crypto") createAccount.set("Crypto");
  });
  $: if (!curListLoading && !curTypeLoading && !bankListLoading)
    filterCurrency();
</script>

<SideMenuBar />

<div class="left-padding">
  {#if $createAccount === "Bank"}
    <BankAccount />
  {/if}
  {#if $createAccount === "Crypto"}
    <CryptoAccount />
  {/if}
</div>

<style lang="scss">.left-padding {
  padding-left: 2rem;
}</style>
