<div class="overlay-spinner">
  <img
    src="../../vendor/image/loading/loading.gif"
    alt="loading"
    class="dashboard-loading"
  />
</div>

<style lang="scss">.overlay-spinner {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.5);
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  transition: 0.3s ease;
  -webkit-transition: 0.3s ease;
}

.dashboard-loading {
  width: 5rem;
}</style>
