<script>
  import { onMount } from "svelte";
  import { fly } from "svelte/transition";
  import { Icon } from "svelte-fontawesome";
  import { faSync, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
  import { PaginationNav } from "svelte-paginate";
  import { getNotificationsContext } from "svelte-notifications";

  import {
    cryptoWhitelist,
    total_cryptos,
    crypto_current_page,
    userInfo,
    crypto_page_size,
    isUserInfoLoading,
    isCryptoWhitelistLoading,
    isCurListLoading,
    newCryptoModal,
  } from "../../store";
  import {
    getCryptoAccounts,
    deleteCryptoAccount,
  } from "../../services/create-account";

  import isLastPage from "../../utils/pagination/isLastPage";

  import DashboardSpinner from "../loading/DashboardSpinner.svelte";
  import CryptoAccountItem from "./CryptoAccountItem.svelte";
  import NewCryptoAccountModal from "./NewCryptoAccountModal.svelte";
  import PrevBtn from "../pagination/PrevBtn.svelte";
  import NextBtn from "../pagination/NextBtn.svelte";

  const { addNotification } = getNotificationsContext();

  let currentPage;
  let pageSize;
  let totalAccounts;

  let disabledPrev = false;
  let disabledNext = false;

  total_cryptos.subscribe((v) => {
    totalAccounts = v;
  });
  crypto_current_page.subscribe((v) => {
    currentPage = v;
  });
  crypto_page_size.subscribe((v) => {
    pageSize = v;
  });

  onMount(() => {
    if (isLastPage(currentPage, "crypto_account")) disabledNext = true;
    else disabledNext = false;
  });

  const reloadCryptoAccount = async () => {
    await getCryptoAccounts();
  };

  const onSetPage = async (e) => {
    crypto_current_page.set(e.detail.page);

    if (isLastPage(currentPage, "crypto_account")) disabledNext = true;
    else disabledNext = false;

    await getCryptoAccounts();
  };

  const showNewCryptoModal = () => {
    if (!$userInfo.twofa_enabled) {
      addNotification({
        text: "First enable 2FA",
        position: "top-right",
        type: "warning",
        removeAfter: 4000,
      });
    } else {
      newCryptoModal.set(true);
    }
  };

  const prevPage = async () => {
    if (currentPage !== 1) {
      current_page.set(currentPage - 1);

      await getCryptoAccounts();
    }
  };

  const nextPage = async () => {
    if (!isLastPage(currentPage, "crypto_account")) {
      current_page.set(currentPage + 1);

      await getCryptoAccounts();
    }
  };

  const onDeleteAccount = async (crypto_account_id) => {
    const res = await deleteCryptoAccount(crypto_account_id);

    if (res === 0)
      addNotification({
        text: "Successfully deleted",
        position: "top-right",
        type: "success",
        removeAfter: 4000,
      });
  };

  $: if (currentPage === 1) disabledPrev = true;
  else disabledPrev = false;
  $: if (isLastPage(currentPage, "crypto_account")) disabledNext = true;
  else disabledNext = false;
</script>

<NewCryptoAccountModal />
<div
  class="d-flex justify-content-center align-items-center"
  transition:fly={{ duration: 500, x: 300 }}
>
  <div class="crypto-account">
    <div class="sync-icon" on:click={reloadCryptoAccount}>
      <Icon icon={faSync} />
    </div>
    <p class="fs-5 cl-primary">Crypto Addresses</p>

    <div class="crypto-accounts-field">
      <div class="crypto-accounts-field-size">
        <div class="crypto-accounts-header">
          <div class="row mx-0">
            <div class="col-2 text-center cl-primary">Name</div>
            <div class="col-4 text-center cl-primary">Address</div>
            <div class="col-1 text-center cl-primary">Currency</div>
            <div class="col-2 text-center cl-primary">Tag</div>
            <div class="col-2 text-center cl-primary">Created</div>
            <div class="col-1 text-center cl-primary">Action</div>
          </div>
        </div>

        <div class="crypto-accounts-list">
          {#if $isCryptoWhitelistLoading || $isCurListLoading}
            <DashboardSpinner />
          {:else}
            {#each $cryptoWhitelist as crypto}
              <CryptoAccountItem account={crypto} {onDeleteAccount} />
            {/each}
          {/if}
        </div>
      </div>
    </div>

    <div class="page d-flex justify-content-center align-items-center">
      <PrevBtn {prevPage} {disabledPrev} />
      <PaginationNav
        totalItems={totalAccounts}
        {pageSize}
        {currentPage}
        limit={1}
        showStepOptions={false}
        on:setPage={onSetPage}
      />
      <NextBtn {nextPage} {disabledNext} />
    </div>

    {#if !$isUserInfoLoading}
      <div class="d-flex justify-content-center mt-2">
        <button type="button" class="btn-main" on:click={showNewCryptoModal}>
          <Icon icon={faPlusCircle} />
          <span class="ms-2">Add Crypto Address</span>
        </button>
      </div>
    {/if}
  </div>
</div>

<style lang="scss">.crypto-account {
  position: relative;
  background-color: rgba(128, 128, 128, 0.25);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 18px 0 rgba(0, 0, 0, 0.19);
  border-radius: 0.2rem;
  padding: 0.8rem;
  width: 100%;
}

.crypto-accounts {
  position: relative;
  background-color: rgba(128, 128, 128, 0.25);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 18px 0 rgba(0, 0, 0, 0.19);
  border-radius: 0.2rem;
  padding: 0.8rem;
}

.sync-icon {
  background-color: #b471ae;
  border-radius: 50%;
  padding: 0.3rem;
  position: absolute;
  right: 0.8rem;
  height: 2.3rem;
  width: 2.3rem;
  color: white;
}

.sync-icon:hover {
  cursor: pointer;
  transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  transition: transform 1s ease-in-out;
  -webkit-transition: transform 1s ease-in-out;
}

.crypto-accounts-field {
  background-color: white;
  padding: 0 0.5rem 0.5rem 0.5rem;
  border-radius: 0.2rem;
  overflow: auto;
}

.crypto-accounts-field-size {
  min-width: 800px;
  max-height: 58vh;
}

.crypto-accounts-header {
  position: sticky;
  top: -1px;
  border-bottom: 2px solid #b471ae;
  padding: 1rem 0 0.5rem 0;
  background-color: white;
}

.crypto-accounts-list {
  padding: 0.5rem 0;
}

.page :global(.pagination-nav) {
  margin-top: 0.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page :global(.option) {
  margin: 0.1rem;
  padding: 0.3rem 0.6rem;
  color: #0a5b76;
  display: flex;
  justify-content: center;
  border-radius: 0.5rem;
}

.page :global(.option.active) {
  color: #b471ae;
  background-color: rgba(180, 113, 174, 0.25);
}

.page :global(.option:hover) {
  cursor: pointer;
  color: #b471ae;
  background-color: rgba(180, 113, 174, 0.25);
}

.page :global(.option.prev) {
  color: #964f90;
}

.page :global(.option.next) {
  color: #964f90;
}

.page :global(.option.disabled) {
  color: gray;
}</style>
