<script>
  import { fly, fade } from "svelte/transition";
  import { Icon } from "svelte-fontawesome";
  import { faInfoCircle, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
  import Popover from "svelte-easy-popover";

  import { cursList, transType } from "../../store";
  import insertThousandSeparator from "../../utils/thousand_separator";

  export let pendTran;

  let referenceElement;

  let dr_cur = $cursList.find((item) => item.cur_id == pendTran.dr_cur_id);
  let cr_cur = $cursList.find((item) => item.cur_id == pendTran.cr_cur_id);
  let show_dr =
    pendTran.type === 1 ||
    pendTran.type === 4 ||
    pendTran.type === 5 ||
    pendTran.type === 6 ||
    pendTran.type === 8 ||
    pendTran.type === 10 ||
    pendTran.type === 11 ||
    pendTran.type === 12 ||
    pendTran.type === 13;
  let show_cr =
    pendTran.type === 2 ||
    pendTran.type === 3 ||
    (pendTran.type === 5 && pendTran.dr_cur_id !== pendTran.cr_cur_id) ||
    pendTran.type === 7 ||
    (pendTran.type === 8 && pendTran.dr_cur_id !== pendTran.cr_cur_id) ||
    (pendTran.type === 10 && pendTran.dr_cur_id !== pendTran.cr_cur_id) ||
    (pendTran.type === 11 && pendTran.dr_cur_id !== pendTran.cr_cur_id) ||
    (pendTran.type === 12 && pendTran.dr_cur_id !== pendTran.cr_cur_id) ||
    (pendTran.type === 13 && pendTran.dr_cur_id !== pendTran.cr_cur_id);
</script>

<div
  class="row mx-0 py-1 my-1 transaction-item"
  in:fly={{ duration: 500, y: 500 }}
>
  <div class="col-3 d-flex flex-wrap justify-content-center align-items-center">
    <div>
      <span>{new Date(pendTran.time).toLocaleDateString()}</span>
      <span>{new Date(pendTran.time).toLocaleTimeString("it-IT")}</span>
    </div>
  </div>
  <div class="col-3 d-flex justify-content-center align-items-center">
    {$transType[parseInt(pendTran.type - 1)]}
  </div>
  <div
    class="col-2 d-flex flex-column justify-content-center align-items-center"
  >
    {#if show_dr}
      <div class="d-flex justify-content-center align-items-center">
        <img
          src={`../../vendor/image/${dr_cur.icon_image}`}
          alt="asset"
          class="asset-icon me-2"
        />
        <span>{dr_cur.symbol}</span>
      </div>
    {/if}
    {#if show_cr}
      <div class="d-flex justify-content-center align-items-center">
        <img
          src={`../../vendor/image/${cr_cur.icon_image}`}
          alt="asset"
          class="asset-icon me-2"
        />
        <span>{cr_cur.symbol}</span>
      </div>
    {/if}
  </div>
  <div
    class="col-2 d-flex flex-column justify-content-center align-items-center"
  >
    {#if show_dr}
      <span
        >{insertThousandSeparator(pendTran.dr_amount.toFixed(dr_cur.dec))}</span
      >
    {/if}
    {#if show_cr}
      <span
        >{insertThousandSeparator(pendTran.cr_amount.toFixed(cr_cur.dec))}</span
      >
    {/if}
  </div>
  <div class="col-2 d-flex justify-content-center align-items-center">
    <div class="d-flex info-icon me-2">
      <Icon icon={faInfoCircle} />
    </div>
    <button
      type="button"
      class="btn btn-danger btn-sm rounded-circle"
      bind:this={referenceElement}
    >
      <Icon icon={faTrashAlt} />
    </button>

    <Popover
      triggerEvents={["focus", "click"]}
      {referenceElement}
      placement="left"
      spaceAway={10}
      closeOnClickAway
    >
      <div class="popover-contents" transition:fade={{ duration: 250 }}>
        <p>Do you really delete this?</p>
        <div class="d-flex justify-content-end align-items-center">
          <button type="button" class="btn btn-sm btn-light me-2">Yes</button>
          <button type="button" class="btn btn-sm btn-light">No</button>
        </div>
      </div>
    </Popover>
  </div>
</div>

<style lang="scss">.asset-icon {
  width: 1.8rem;
}

.transaction-item {
  border-radius: 0.2rem;
}

.transaction-item:hover {
  background-color: rgba(180, 113, 174, 0.25);
}

.info-icon {
  font-size: 1.25rem;
  transition: color 0.2s ease-in-out;
  -webkit-transition: color 0.2s ease-in-out;
}

.info-icon:hover {
  cursor: pointer;
  color: #13b5ec;
}

.delete-icon {
  background-color: #ff4d4d;
  padding: 0.3rem;
  border-radius: 50%;
  color: white;
}

.delete-icon:hover {
  cursor: pointer;
  background-color: #ff1a1a;
}

.popover-contents {
  background-color: #dcbcd9;
  border-radius: 0.5rem;
  padding: 0.5rem;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 18px 0 rgba(0, 0, 0, 0.19);
}</style>
