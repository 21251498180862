<div class="spinner-bg-size">
  <div class="d-flex justify-content-center align-items-center">
    <img
      src="../../vendor/image/loading/loading.gif"
      alt="loading"
      class="dashboard-loading"
    />
  </div>
</div>

<style lang="scss">.spinner-bg-size {
  width: 100%;
}

.dashboard-loading {
  width: 5rem;
}</style>
